import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import * as Path from "Routes/paths";
import FadeLoader from "react-spinners/FadeLoader";

import "./Auth.scss";
// images
import AuthBg from "../../Assets/Images/AuthImg.png";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import BannerMessage from "components/shared/BannerMessage";

const AuthLayout = ({ loader }) => {
  const location = useLocation();
  const { pathname } = location;

  const bannerSettings = useSelector((state) => state.Misc.bannerSettings);

  const override = css`
    display: block;
    margin: 0 auto;
  `;
  let [color, setColor] = useState("#f4694b");

  useEffect(() => {
    // Update the section height on window resize
    const handleResize = () => {
      document.querySelector(".AuthLayout").style.height =
        window.innerHeight + "px";
    };

    // Initial setup
    handleResize();

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {bannerSettings?.message && (
        <div className="nta-banner mb-2 fixed-top">
          <BannerMessage
            data={bannerSettings}
          />
        </div>
      )}
      <section
        className="AuthLayout py-5 d-flex align-items-center justify-content-center w-100"
        style={{ zIndex: "9" }}
      >
        {loader == true &&
          <div className="loader-overlay">
            <div className="main-loader_">
              <FadeLoader color={color} loading={loader} css={override} size={300} />
            </div>
          </div>
        }
        <Col lg="6" className="imgWrpper h-100 position-absolute">
          <img
            src={AuthBg}
            alt=""
            className="img-fluid w-100 h-100"
            style={{ objectFit: "cover" }}
          />
        </Col>
        <Container>
          <Row className="justify-content-end">
            <Col lg="6" className="my-2">
              <Outlet />
            </Col>
          </Row>
          {pathname === Path.login && (
            <Row className="justify-content-end relative">
              <Col lg="6" className="position-absolute" style={{ right: 0, bottom: 10 }}>
                <a href="https://nutri-q.com/privacy-policy/" target="_blank" className="" style={{ left: 20, bottom: 10 }}>Privacy Policy</a>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loader: state.persistStore.loader,
  };
};

export default connect(mapStateToPros)(AuthLayout);
